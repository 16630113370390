.ams-sp-modal, #ams-stegplaner {
    .ams-product-item-modules {
        font-size: 14px;
        > *:not(:first-child) {
            margin-top: 40px;
        }
        .ams-product-item-module-title {
            font-weight: 900;
            font-size: 1.25em;
            font-size: 20px;
            font-weight: 900;
            line-height: 25px;
            > span {
                float: left;
                max-width: calc(100% - 18px);
            }
            > svg {
                float: right;
                cursor: pointer;
            }
            &::after {
                content: '';
                clear: both;
            }
            clear: both;
        }
        .ams-product-item-module-info {
            font-weight: 700;
            margin-bottom: 5px;
            min-height: 53px;
            clear: both;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
        }
        .ams-product-item-module-messures {
            font-weight: 700;
            font-size: 0.8em;
            margin-bottom: 5px;
            clear: both;
        }
        .ams-product-item-module-subs {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            list-style: none;
            margin-left: -20px;
            margin-bottom: -20px;
            clear: both;
            .ams-product-item-module-sub {
                width: 20%;
                @media (max-width: 538px) {
                    width: 25%;
                }
                @media (max-width: 455px) {
                    width: calc(100% * 1 / 3.001);
                }
                @media (max-width: 373px) {
                    width: 50%;
                }
                @media (max-width: 320px) {
                    width: 100%;
                }
                padding-left: 20px;
                position: relative;
                cursor: pointer;
                margin-bottom: 20px;
                /*display: flex;*/
                .ams-product-item-module-sub-image {
                    position: relative;
                    img {
                        max-width: 100%;
                        width: 100%;
                    }
                }
                .ams-product-item-module-sub-title {
                    /*                    position: absolute;
                                        bottom: 0;
                                        right: 0;*/
                    /*background-color: var(--ams-sp-menu-variant-bg-color);*/
                    /*color: var(--ams-sp-menu-variant-color);*/
                    padding: 5px 0;
                    /*                    min-width: calc(50% - 10px);
                                        max-width: calc(80% - 10px);*/
                    text-align: left;
                    hyphens: auto;
                    font-size: 0.8em;
                    font-weight: 900;
                }
            }
            /*            .ams-product-item-module-sub {
                            width: calc(100% * 1 / 6.001);
                            @media (max-width: 538px) {
                                width: 20%;
                            }
                            @media (max-width: 455px) {
                                width: 25%;
                            }
                            @media (max-width: 373px) {
                                width: calc(100% * 1 / 3.001);
                            }
                            @media (max-width: 320px) {
                                width: 50%;
                            }
                            @media (max-width: 270px) {
                                width: 100%;
                            }
                        }*/
            /*            .ams-product-item-module-sub {
                            width: calc(100% * 1 / 3.001);
                            @media (max-width: 482px) {
                                width: 50%;
                            }
                        }*/
        }
    }
}