.ams-sp-modal, #ams-stegplaner {
    .ams-product-item-boat_lifts {
        font-size: 14px;
        > *:not(:first-child) {
            margin-top: 20px;
        }
        .ams-product-item-boat_lift-title {
            font-weight: 900;
            font-size: 1.25em;
            font-size: 20px;
            font-weight: 900;
            line-height: 25px;
            > span {
                float: left;
                max-width: calc(100% - 18px);
            }
            > svg {
                float: right;
                cursor: pointer;
            }
            &::after {
                content: '';
                clear: both;
            }
            clear: both;
        }
        .ams-product-item-boat_lift-info {
            font-weight: 700;
            margin-bottom: 5px;
            min-height: 53px;
            clear: both;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
        }
        .ams-product-item-boat_lift-messures {
            font-weight: 700;
            font-size: 0.8em;
            margin-bottom: 5px;
            display: none;
            clear: both;
        }
        .ams-product-item-boat_lift-subs {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            list-style: none;
            margin-left: -20px;
            margin-bottom: -20px;
            /*justify-content: center;*/
            clear: both;
            padding-top: 20px;
            .ams-product-item-boat_lift-sub {
                padding-left: 20px;
                position: relative;
                margin-bottom: 20px;
                display: flex;
                img {
                    max-width: 100%;
                    width: 100%;
                }
                .ams-product-item-boat_lift-sub-title {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    background-color: var(--ams-sp-menu-variant-bg-color);
                    color: var(--ams-sp-menu-variant-color);
                    padding: 5px 10px;
                    min-width: calc(50% - 10px);
                    max-width: calc(80% - 10px);
                    text-align: right;
                    hyphens: auto;
                }

                > .ams-product-item-boat_lift-image {
                    background-color: #7A8A9333;
                    padding: 20px;
                    border: 2px solid #7A8A93;
                    border-radius: 20px;
                    cursor: pointer;
                    width: calc(100% * 1 / 3.001);
                    position: relative;
                    @media (max-width: 320px) {
                        width: 50%;
                    }
                }

                > .ams-product-item-boat_lift-sub-short-info {
                    padding: 20px;
                    width: calc(100% * 2 / 3.001);
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 20px;
                    @media (max-width: 320px) {
                        width: 50%;
                    }
                }
            }
        }
    }
    .ams-stegplaner-app-small {
        .ams-product-item-boat_lifts {
            .ams-product-item-boat_lift-subs {
                .ams-product-item-boat_lift-sub {
                    display: block;
                    > .ams-product-item-boat_lift-image {
                        width: 50%;
                    }
                    > .ams-product-item-boat_lift-sub-short-info {
                        padding: 0px;
                        padding-top: 20px;
                        width: 100%;
                    }
                }
            }
        }
    }
}