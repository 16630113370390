#ams-stegplaner {
    .ams-menu-body-left {
        width: var(--ams-sp-menu-sidebar-width);
        position: relative;
        .ams-menu-body-left-body {
            height: 100%;
            position: relative;
            margin-top: var(--ams-sp-menu-sidebar-margin-right);
            margin-bottom: var(--ams-sp-menu-sidebar-margin-left);
            height: calc(100% - var(--ams-sp-menu-sidebar-margin-right) - var(--ams-sp-menu-sidebar-margin-left));
            overflow: hidden;
            .ams-mblb-container {
                transform: rotate(270deg);
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                white-space: nowrap;
                overflow: hidden;
                flex-wrap: nowrap;
                .ams-mblb-container-left {
                    height: 100%;
                    white-space: nowrap;
                }
                .ams-mblb-container-right {
                    height: 100%;
                    margin-left: var(--ams-sp-menu-sidebar-margin-between);
                    white-space: nowrap;
                }
                .ams-menu-sidebar-item {
                    --ams-sp-menu-sidebar-bg-image-width: 500px;
                    display: inline-block;
                    height: 100%;
                    padding: 0 var(--ams-sp-menu-sidebar-item-padding);
                    font-size: var(--ams-sp-menu-sidebar-item-font-size);
                    font-weight: var(--ams-sp-menu-sidebar-item-font-weight);
                    vertical-align: middle;
                    border-top-left-radius: var(--ams-sp-menu-sidebar-item-border-radius);
                    border-top-right-radius: var(--ams-sp-menu-sidebar-item-border-radius);
                    cursor: pointer;
                    text-transform: uppercase;

                    color: var(--ams-sp-menu-sidebar-item-color);
                    background-color: var(--ams-sp-menu-sidebar-item-bg);
                    position: relative;
                    overflow: hidden;
                    &::before {
                        content: "";
                        position: absolute;
                        width: var(--ams-sp-menu-sidebar-width);
                        height: var(--ams-sp-menu-sidebar-bg-image-width);
                        top: calc((var(--ams-sp-menu-sidebar-width) - var(--ams-sp-menu-sidebar-bg-image-width)) / 2);
                        left: calc((var(--ams-sp-menu-sidebar-bg-image-width) - var(--ams-sp-menu-sidebar-width)) / 2);
                        z-index: -1;
                        background-image: var(--image-background);
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: 50% 100%;
                        transform: rotate(90deg);
                    }
                    box-shadow: var(--ams-sp-menu-sidebar-item-box-shadow);
                    &.ams-item-selected {
                        color: var(--ams-sp-menu-sidebar-item-color-selected);
                        background-color: var(--ams-sp-menu-sidebar-item-bg-selected);
                        box-shadow: var(--ams-sp-menu-sidebar-item-box-shadow-selected);
                    }
                    &:hover {
                        color: var(--ams-sp-menu-sidebar-item-color-hover);
                        background-color: var(--ams-sp-menu-sidebar-item-bg-hover);
                        box-shadow: var(--ams-sp-menu-sidebar-item-box-shadow-selected);
                    }

                    &.ams-menu-sidebar-item-first {
                        color: var(--ams-sp-menu-sidebar-item-first-color);
                        background-color: var(--ams-sp-menu-sidebar-item-first-bg);
                        box-shadow: var(--ams-sp-menu-sidebar-item-first-box-shadow);
                        &.ams-item-selected {
                            color: var(--ams-sp-menu-sidebar-item-first-color-selected);
                            background-color: var(--ams-sp-menu-sidebar-item-first-bg-selected);
                            box-shadow: var(--ams-sp-menu-sidebar-item-first-box-shadow-selected);
                        }
                        &:hover {
                            color: var(--ams-sp-menu-sidebar-item-first-color-hover);
                            background-color: var(--ams-sp-menu-sidebar-item-first-bg-hover);
                            box-shadow: var(--ams-sp-menu-sidebar-item-first-box-shadow-hover);
                        }
                    }

                    &.ams-menu-sidebar-item-second {
                        color: var(--ams-sp-menu-sidebar-item-second-color);
                        background-color: var(--ams-sp-menu-sidebar-item-second-bg);
                        box-shadow: var(--ams-sp-menu-sidebar-item-second-box-shadow);
                        &.ams-item-selected {
                            color: var(--ams-sp-menu-sidebar-item-second-color-selected);
                            background-color: var(--ams-sp-menu-sidebar-item-second-bg-selected);
                            box-shadow: var(--ams-sp-menu-sidebar-item-second-box-shadow-selected);
                        }
                        &:hover {
                            color: var(--ams-sp-menu-sidebar-item-second-color-hover);
                            background-color: var(--ams-sp-menu-sidebar-item-second-bg-hover);
                            box-shadow: var(--ams-sp-menu-sidebar-item-second-box-shadow-hover);
                        }
                    }

                    > div {
                        height: 100%;
                        display: flex;
                        align-items: center;
                    }
                    &:not(:last-child) {
                        margin-right: var(--ams-sp-menu-sidebar-item-margin-right);
                    }
                }
            }
        }
    }
    .ams-stegplaner-current-menu-start {
        .ams-menu-body-left {
            visibility: hidden;
        }
        &.ams-stegplaner-app-small {
            .ams-menu-body-left {
                display: none;
            }
        }
    }
}