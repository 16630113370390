#ams-stegplaner, .modal.ams-sp-modal {
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    *, ::after, ::before {
        box-sizing: border-box;
    }
}
#ams-stegplaner {
    position: relative;
    .ams-stegplaner-app {
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        /* width */
        ::-webkit-scrollbar {
            width: 5px;
            padding-right: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px var(--ams-sp-color-button-light-gray);
            border-radius: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track:hover {
            box-shadow: inset 0 0 5px var(--ams-sp-color-button-dark-gray);
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: var(--ams-sp-color-button-light-gray);
            border-radius: 5px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: var(--ams-sp-color-button-dark-gray);
        }
    }
    .display-none {
        display: none !important;
    }
    .visibility-hidden {
        visibility: hidden !important;
    }
}