.ams-sp-modal, #ams-stegplaner {
    .ams-shared-item-example_boats {
        margin-right: 25%;
        font-size: 14px;
        > *:not(:first-child) {
            margin-top: 20px;
        }
        .ams-shared-item-example_boats-title {
            font-weight: 900;
            font-size: 1.25em;
            font-size: 20px;
            font-weight: 900;
            line-height: 25px;
            > span {
                float: left;
                max-width: calc(100% - 18px);
            }
            > svg {
                float: right;
                cursor: pointer;
            }
            &::after {
                content: '';
                clear: both;
            }
            clear: both;
        }
        .ams-shared-item-example_boats-info {
            font-weight: 700;
            margin-bottom: 5px;
            /*min-height: 53px;*/
            clear: both;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
        }
        .ams-shared-item-example_boats-messures {
            font-weight: 700;
            font-size: 0.8em;
            margin-bottom: 5px;
            display: none;
            clear: both;
        }
        .ams-shared-item-example_boats-subs {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            list-style: none;
            margin-left: -20px;
            margin-bottom: -20px;
            clear: both;
            .ams-shared-item-example_boats-sub {
                width: calc(100% * 1 / 3.001);
                @media (max-width: 320px) {
                    width: 50%;
                }
                padding-left: 20px;
                position: relative;
                margin-bottom: 20px;
                display: flex;
                img {
                    max-width: 100%;
                    width: 100%;
                }
                .ams-shared-item-example_boats-sub-title {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    background-color: var(--ams-sp-menu-variant-bg-color);
                    color: var(--ams-sp-menu-variant-color);
                    padding: 5px 10px;
                    min-width: calc(50% - 10px);
                    max-width: calc(80% - 10px);
                    text-align: right;
                    hyphens: auto;
                }

                .ams-shared-item-example_boats-scontainer {
                    display: flex;
                    flex-direction: column;
                    > div {
                        width: 100%;
                    }
                    > .ams-shared-item-example_boats-images {
                        flex-grow: 1;
                        display: flex;
                        /*align-items: center;*/
                        align-items: flex-end;
                        justify-content: center;

                        background-color: #7A8A9333;
                        padding: 20px;
                        border: 2px solid #7A8A93;
                        border-radius: 20px;
                        position: relative;
                        cursor: pointer;
                    }
                    .ams-shared-item-example_boats-info {
                        padding: 10px 10px;
                        text-align: center;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 20px;
                    }
                }
                &:nth-child(1) {
                    .ams-shared-item-example_boats-images {
                        img {
                            width: 50%;
                            transform: rotate(180deg);
                        }
                    }
                }
                &:nth-child(2) {
                    .ams-shared-item-example_boats-images {
                        img {
                            width: 85%;
                        }
                    }
                }
                /*                &:nth-child(3) {
                                }*/
            }
        }
    }
    .ams-stegplaner-app-small {
        .ams-shared-item-example_boats {
            margin-right: 0;
            .ams-shared-item-example_boats-subs {
                .ams-shared-item-example_boats-sub {
                    .ams-shared-item-example_boats-scontainer {
                        > .ams-shared-item-example_boats-images {
                            padding: 15px;
                        }
                    }
                }
            }
        }
    }
}