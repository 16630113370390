#ams-stegplaner {
    .ams-stegplaner-app {
        .ams-menu-container {
            margin-top: 0;
            margin-right: 30px;
            margin-bottom: 0;
            margin-left: 30px;
            height: 830px;
            .ams-menu-imprint-data-protect {
                margin-top: calc(-1 * var(--object-height));
                display: flex;
                justify-content: flex-end;
                padding: 10px 0;
                > div {
                    width: calc(100% - var(--ams-sp-menu-sidebar-width));
                    .ams-sp-imprint-data-protect {
                        display: flex;
                        justify-content: flex-end;
                        font-size: 12px;
                        > a:not(:first-child) {
                            margin-left: 20px;
                        }
                    }
                }
            }
        }
        &:not(.ams-stegplaner-app-small) {
            .ams-menu-container {
                margin: 0;
                height: 100%;
                width: 560px;
            }
        }
        &.ams-stegplaner-app-small {
            .ams-menu-container {
                height: 600px;
            }
        }
    }
}