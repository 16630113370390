.ams-sp-modal, #ams-stegplaner {
    .ams-products-plus {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            &, * {
                fill: #58585A88;
                stroke: #FFFFFF;
            }
            filter: drop-shadow(0 0 2px #58585ABB);
        }
        &:hover {
            svg {
                &, * {
                    fill: #FFFFFF;
                    stroke: #58585A88;
                }
            }
        }
    }
}