#ams-stegplaner {
    .ams-menu-body-right {
        width: calc(100% - var(--ams-sp-menu-sidebar-width));
        height: 100%;
        .ams-menu-body-right-body {
            border: var(--ams-sp-menu-border-width) solid var(--ams-sp-menu-border-color);
            border-radius: var(--ams-sp-menu-border-radius);
            height: 100%;
            -webkit-box-shadow: var(--ams-sp-menu-box-shadow);
            box-shadow: var(--ams-sp-menu-box-shadow);
            background-color: var(--ams-sp-menu-bg-color);
            background-image: var(--image-background);
            background-repeat: no-repeat;
            background-color: transparent;
            background-size: cover;
            background-position-y: 50%;
            background-position-x: 50%;
            padding-top: var(--ams-sp-menu-header-top-bottom);
            z-index: 2;
            .ams-mbrb-head {
                background-color: var(--ams-sp-menu-header-bg-color);
                color: var(--ams-sp-menu-header-color);
                border-radius: var(--ams-sp-menu-header-border-radius);
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: var(--ams-sp-menu-header-margin);
                .ams-mbrb-head-title {
                    padding: var(--ams-sp-menu-header-padding);
                    font-size: var(--ams-sp-menu-header-font-size);
                    line-height: var(--ams-sp-menu-header-line-height);
                    font-weight: 900;
                    text-transform: uppercase;
                    display: inline-block;
                    z-index: 1;
                }

                position: relative;
                &:before {
                    content: '';
                    background-image: var(--image-background);
                    background-repeat: no-repeat;
                    background-color: transparent;
                    background-size: contain;
                    background-position-y: 50%;
                    background-position-x: 100%;
                    position: absolute;
                    margin: var(--ams-sp-menu-header-padding);
                    top: 0;
                    bottom: 0;
                    right: 0;
                    width: calc(100% - (2 * var(--ams-sp-menu-header-padding)));
                }
            }
            .ams-mbrb-body {
                height: 100%;
                border-bottom-left-radius: var(--ams-sp-menu-border-radius-inner);
                border-bottom-right-radius: var(--ams-sp-menu-border-radius-inner);
                padding: 30px 0;
                position: relative;
                .ams-mbrb-body-container {
                    overflow-y: auto;
                    min-height: 100%;
                    height: 100%;
                    position: relative;
                    &, * {
                        hyphens: auto;
                    }
                }
            }
        }
    }
    .ams-stegplaner-current-menu-start {
        .ams-menu-body-right {
            .ams-menu-body-right-body {
                .ams-mbrb-head {
                    justify-content: center;
                    &:before {
                        display: none;
                    }
                }
            }
        }
        &.ams-stegplaner-app-small {
            .ams-menu-body-right {
                width: 100%;
            }
        }
    }
    .ams-stegplaner-app-small {
        .ams-menu-body-right {
            .ams-menu-body-right-body {
                .ams-mbrb-head {
                    .ams-mbrb-head-title {
                        font-size: 26px;
                    }
                }
            }
        }
    }
}