#ams-stegplaner {
    .ams-stegplaner-app {
        .ams-visual3d-area {
            position: relative;
            width: 100%;
            height: 100%;
            height: 100vh;
            height: -webkit-fill-available;
            overflow: hidden;
            .ams-visual3d-loading {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px;
            }
            iframe {
                width: 100%;
                height: 100%;
                height: 100vh;
                height: -webkit-fill-available;
            }
            .ams-visual3d-area-close {
                position: absolute;
                left: 50%;
                bottom: 20px;
                transform: translateX(-50%);

                font-size: var(--ams-sp-footer-font-size);
                font-weight: var(--ams-sp-footer-font-weight);
                line-height: 1em;
                text-transform: uppercase;

                /*                border-radius: 50%;
                                font-size: 28px;
                                line-height: 1;
                                width: 2em;
                                height: 2em;
                                padding: 0;
                                display: flex;
                                justify-content: center;
                                align-items: center;*/
            }
        }
    }
}