#ams-stegplaner {
    .ams-sp-menu-item-send-header {
        font-weight: 700;
        font-size: 1.5em;
        margin-bottom: 20px;
    }
    .ams-sp-menu-item-send-result {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        padding: 40px;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 1.5em;
        &, > * {
            text-align: center;
        }
    }
}
