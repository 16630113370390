#ams-stegplaner {
    .ams-stegplaner-app {
        .ams-menu {
            max-width: 620px;
            margin-left: auto;
            margin-right: auto;
        }
        &:not(.ams-stegplaner-app-small) {
            .ams-menu {
                position: absolute;
                right: 40px;
                top: 60px;
                /*top: 12px;*/
                bottom: 92px;
                max-width: none;
                margin-left: 0;
                margin-right: 0;
                z-index: 10;
            }
        }
    }
}