#ams-stegplaner {
    .ams-stegplaner-app {
        .ams-visual-area-container {
            position: relative;
            .ams-visual-area-start-info {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 600px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 42px;
                font-weight: 900;
                padding: 5%;
            }
        }
        &.ams-stegplaner-app-small {
            .ams-visual-area-container {
                .ams-visual-area-start-info {
                    right: 0;
                }
            }
        }
        .ams-visual-area {
            /*            position: static !important;
                        @media (min-width: 1024px) {
                            position: absolute !important;
                            top: 0;
                            left: 0;
                        }*/
        }
    }
}