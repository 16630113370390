.ams-sp-modal.modal {
    .modal-content {
        background-color: var(--ams-sp-menu-bg-color);
        background-image: var(--image-background);
        background-repeat: no-repeat;
        background-color: transparent;
        background-size: cover;
        background-position-y: 50%;
        background-position-x: 50%;
        .modal-header {
            background-color: var(--ams-sp-menu-header-bg-color);
            color: var(--ams-sp-menu-header-color);
            border-radius: var(--ams-sp-menu-header-border-radius);
            margin: var(--bs-modal-header-padding);
            padding: var(--bs-modal-header-padding);
            font-size: var(--ams-sp-menu-header-font-size);
            line-height: var(--ams-sp-menu-header-line-height);
            font-weight: 900;
            text-transform: uppercase;
        }
        .modal-body {
            position: relative;
        }
    }
}
