#ams-stegplaner {
    .ams-menu-body {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        padding-bottom: 30px;
    }
    .ams-stegplaner-app-small {
        .ams-menu-body {
            padding-top: 30px;
        }
    }
}