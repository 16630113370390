#ams-stegplaner {
    .ams-footer {
        background-color: var(--ams-sp-footer-bg-color);
        background-image: var(--ams-sp-footer-bg-image);
        font-size: var(--ams-sp-footer-font-size);
        font-weight: var(--ams-sp-footer-font-weight);
        -webkit-box-shadow: var(--ams-sp-footer-box-shadow);
        box-shadow: var(--ams-sp-footer-box-shadow);
        .btn {
            font-size: var(--ams-sp-footer-font-size);
            font-weight: var(--ams-sp-footer-font-weight);
            line-height: 1em;
            text-transform: uppercase;

            &:not(:last-child) {
                margin-right: var(--ams-sp-footer-button-margin-right);
            }
        }
        .ams-footer-zoom {
            .btn {
                padding: 6px 7px;
            }
        }
        .ams-footer-container {
            padding: var(--ams-sp-footer-padding);
        }
        .ams-footer-imprint {
            .ams-sp-imprint-data-protect {
                display: flex;
                justify-content: space-between;
                font-size: 12px;
            }
            .btn {
                &.btn-sm {
                    --bs-btn-font-size: 12px;
                    --bs-btn-padding-x: 12px;
                    --bs-btn-padding-y: 3px;
                    --ams-sp-footer-font-size: 12px;
                    --bs-btn-font-weight: 900;
                    --ams-sp-footer-font-weight: 900;
                    line-height: 1.5em;
                }
            }
            > div {
                width: 100%;
            }
        }
    }
    .ams-stegplaner-app-small {
        .ams-footer {
            .ams-footer-zoom {
                .btn {
                    width: calc(100% * 1 / 3.001);
                }
            }
        }
    }
    .ams-stegplaner-app:not(.ams-stegplaner-app-small) {
        .ams-footer {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }
        .ams-footer-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .ams-footer-back-next {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .ams-footer-reset-save, .ams-footer-3d-ansicht, .ams-footer-back-next {
            .btn {
                min-width: 175px;
            }
        }
    }
    .ams-stegplaner-app.ams-stegplaner-app-small {
        .ams-footer-reset-save, .ams-footer-back-next {
            .btn {
                min-width: 45%;
            }
        }
        .ams-footer-3d-ansicht {
            .btn {
                width: 100%;
            }
        }
        .ams-footer-container {
            > * {
                width: 100% !important;
                display: flex;
                justify-content: space-between;
                align-items: center;
                &:not(:last-child) {
                    margin-bottom: var(--ams-sp-footer-button-margin-right);
                }
            }
        }
    }
}
