.ams-menu-item-accessories {
    .ams-menu-item-accessories-title {
        font-weight: 900;
        font-size: 1.5em;
        margin-bottom: 20px;
    }
    .ams-menu-item-accessories-items {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;
        margin-left: -20px;
        margin-bottom: -20px;
        /*        justify-content: center;*/
        + .ams-menu-item-accessories-items {
            margin-top: 30px;
        }
        > * {
            /*width: 50%;*/
            width: 100%;
            @media (max-width: 320px) {
                width: 100%;
            }
            padding-left: 20px;
            position: relative;
            margin-bottom: 20px;
            /*display: flex;*/
        }
    }
}