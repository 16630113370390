div + .ams-menu-item-example_boats {
    margin-top: 20px;
}
.ams-menu-item-example_boats {
    .ams-menu-item-example_boats-title {
        font-weight: 900;
        font-size: 1.25em;
        margin-bottom: 20px;
        svg {
            float: right;
            cursor: pointer;
        }
    }
    .ams-menu-item-example_boats-items {
        /*        display: flex;
                flex-wrap: wrap;*/
        margin: 0;
        padding: 0;
        list-style: none;
        margin-left: -20px;
        margin-bottom: -20px;
        /*        justify-content: center;*/
        > * {
            /*            width: 50%;
                        @media (max-width: 320px) {
                            width: 100%;
                        }*/
            padding-left: 20px;
            position: relative;
            margin-bottom: 20px;
            /*display: flex;*/
        }
    }
}