#ams-stegplaner {
    .ams-menu-infos {
        border: 0;
        min-height: 100%;
        position: relative;
        .ams-menu-infos-title {
            font-weight: 900;
            font-size: 1.25em;
            svg {
                float: right;
                cursor: pointer;
            }
            margin-bottom: 20px;
        }
        .ams-menu-infos-info {
            font-weight: 700;
            margin-bottom: 5px;
            min-height: 53px;
            overflow-y: auto;
        }
        .ams-menu-infos-close {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
}