#ams-stegplaner, .modal.ams-sp-modal {
    --ams-sp-font-family: Roboto, sans-serif;

    --ams-sp-color-water-blue: #A8C6D1;
    --ams-sp-color-beach-beige: #E1D9CE;
    --ams-sp-color-toolbar-gray: #B9B9B9;
    --ams-sp-color-button-dark-gray: #58585A;
    --ams-sp-color-button-light-gray: #88888A;
    --ams-sp-color-background-gray: #DDD;

    --ams-sp-menu-bg-color: var(--ams-sp-color-background-gray);
    --ams-sp-menu-border-color: var(--ams-sp-color-toolbar-gray);
    --ams-sp-menu-border-width: 0;
    --ams-sp-menu-border-radius: 10px;
    --ams-sp-menu-border-radius-inner: 10px;
    --ams-sp-menu-box-shadow: -10px 0px 20px 0px var(--ams-sp-color-toolbar-gray);

    --ams-sp-menu-header-color: #000000;
    --ams-sp-menu-header-bg-color: var(--ams-sp-color-toolbar-gray);
    --ams-sp-menu-header-padding: 15px;
    --ams-sp-menu-header-top-bottom: 5px;
    --ams-sp-menu-header-left-right: 10px;
    --ams-sp-menu-header-margin: var(--ams-sp-menu-header-top-bottom) var(--ams-sp-menu-header-left-right);
    --ams-sp-menu-header-border-radius: 10px;
    --ams-sp-menu-header-font-size: 30px;
    --ams-sp-menu-header-line-height: 1em;

    --ams-sp-menu-sidebar-width: 45px;
    --ams-sp-menu-sidebar-margin-right: 56px;
    --ams-sp-menu-sidebar-margin-between: 40px;
    --ams-sp-menu-sidebar-margin-left: 10px;
    --ams-sp-menu-sidebar-item-margin-right: 20px;
    --ams-sp-menu-sidebar-item-padding: 15px;
    --ams-sp-menu-sidebar-item-font-size: 22px;
    --ams-sp-menu-sidebar-item-font-weight: 700;
    --ams-sp-menu-sidebar-item-border-radius: 5px;

    --ams-sp-menu-sidebar-item-color: #FFFFFF;
    --ams-sp-menu-sidebar-item-bg: var(--ams-sp-color-button-light-gray);
    --ams-sp-menu-sidebar-item-box-shadow: inset 0px -15px 15px -10px var(--ams-sp-color-toolbar-gray);
    --ams-sp-menu-sidebar-item-color-selected: var(--ams-sp-menu-sidebar-item-color);
    --ams-sp-menu-sidebar-item-bg-selected: var(--ams-sp-color-button-dark-gray);
    --ams-sp-menu-sidebar-item-box-shadow-selected: var(--ams-sp-menu-sidebar-item-box-shadow);
    --ams-sp-menu-sidebar-item-color-hover: var(--ams-sp-menu-sidebar-item-color-selected);
    --ams-sp-menu-sidebar-item-bg-hover: var(--ams-sp-menu-sidebar-item-bg-selected);
    --ams-sp-menu-sidebar-item-box-shadow-hover: var(--ams-sp-menu-sidebar-item-box-shadow-selected);

    --ams-sp-menu-sidebar-item-first-color: var(--ams-sp-color-button-light-gray);
    --ams-sp-menu-sidebar-item-first-bg: var(--ams-sp-color-toolbar-gray);
    --ams-sp-menu-sidebar-item-first-box-shadow: inset 0px -15px 15px -10px var(--ams-sp-color-button-light-gray);
    --ams-sp-menu-sidebar-item-first-color-selected: var(--ams-sp-color-button-dark-gray);
    --ams-sp-menu-sidebar-item-first-bg-selected: var(--ams-sp-color-toolbar-gray);
    --ams-sp-menu-sidebar-item-first-box-shadow-selected: inset 0px -15px 15px -10px var(--ams-sp-color-button-dark-gray);
    --ams-sp-menu-sidebar-item-first-color-hover: var(--ams-sp-menu-sidebar-item-first-color-selected);
    --ams-sp-menu-sidebar-item-first-bg-hover: var(--ams-sp-menu-sidebar-item-first-bg-selected);
    --ams-sp-menu-sidebar-item-first-box-shadow-hover: var(--ams-sp-menu-sidebar-item-first-box-shadow-selected);

    --ams-sp-menu-sidebar-item-second-color: #FFFFFF;
    --ams-sp-menu-sidebar-item-second-bg: var(--ams-sp-color-button-light-gray);
    --ams-sp-menu-sidebar-item-second-box-shadow: inset 0px -15px 15px -10px var(--ams-sp-color-toolbar-gray);
    --ams-sp-menu-sidebar-item-second-color-selected: #FFFFFF;
    --ams-sp-menu-sidebar-item-second-bg-selected: var(--ams-sp-color-button-dark-gray);
    --ams-sp-menu-sidebar-item-second-box-shadow-selected: var(--ams-sp-menu-sidebar-item-second-box-shadow);
    --ams-sp-menu-sidebar-item-second-color-hover: var(--ams-sp-menu-sidebar-item-second-color-selected);
    --ams-sp-menu-sidebar-item-second-bg-hover: var(--ams-sp-menu-sidebar-item-second-bg-selected);
    --ams-sp-menu-sidebar-item-second-box-shadow-hover: var(--ams-sp-menu-sidebar-item-second-box-shadow-selected);

    --ams-sp-menu-items-padding: 0 20px;

    --ams-sp-menu-variant-color: #FFFFFF;
    --ams-sp-menu-variant-bg-color: var(--ams-sp-color-button-dark-gray);

    --ams-sp-footer-bg-color: var(--ams-sp-color-background-gray);
    --ams-sp-footer-bg-image: linear-gradient(to top, var(--ams-sp-color-background-gray) 0%, var(--ams-sp-color-toolbar-gray) 100%);
    --ams-sp-footer-padding: 20px 40px;
    --ams-sp-footer-font-size: 24px;
    --ams-sp-footer-font-weight: 700;
    --ams-sp-footer-button-margin-right: 20px;
    --ams-sp-footer-box-shadow: 0px -5px 10px 0px var(--ams-sp-color-toolbar-gray);

    --bs-body-font-family: var(--ams-sp-font-family);
    --bs-body-font-size: 16px;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.25;
    --bs-body-color: var(--ams-sp-menu-header-color);
    --bs-body-bg: transparent;
    --bs-body-text-align: left;

    --bs-modal-footer-border-width: 0px;
    --bs-modal-border-color: var(--ams-sp-menu-border-color);
    --bs-modal-border-width: var(--ams-sp-menu-border-width);
    --bs-modal-border-radius: var(--ams-sp-menu-border-radius);
    --bs-modal-box-shadow: var(--ams-sp-menu-box-shadow);
    --bs-modal-bg: var(--ams-sp-menu-bg-color);
    --bs-modal-padding: 35px 20px;
    --bs-modal-footer-gap: 40px;
    --bs-modal-header-border-width: 0;

    .btn {
        --bs-btn-padding-x: 0.75rem;
        --bs-btn-padding-y: 0.375rem;
        --bs-btn-font-family: var(--ams-sp-font-family);
        --bs-btn-font-size: 1rem;
        --bs-btn-font-weight: 400;
        --bs-btn-line-height: 1.5;
        --bs-btn-color: var(--ams-sp-color-button-dark-gray);
        --bs-btn-bg: transparent;
        --bs-btn-border-width: 2px;
        --bs-btn-border-color: transparent;
        --bs-btn-border-radius: 5px;
        --bs-btn-hover-border-color: transparent;
        --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),0 1px 1px rgba(0, 0, 0, 0.075);
        --bs-btn-disabled-opacity: 0.65;
        --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
    }
    .btn-primary {
        --bs-btn-color: #fff;
        --bs-btn-bg: var(--ams-sp-color-button-dark-gray);
        --bs-btn-border-color: var(--ams-sp-color-button-dark-gray);
        --bs-btn-hover-color: var(--ams-sp-color-button-dark-gray);
        --bs-btn-hover-bg: #fff;
        --bs-btn-hover-border-color: var(--ams-sp-color-button-dark-gray);
        --bs-btn-focus-shadow-rgb: 49,132,253;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: var(--ams-sp-color-button-dark-gray);
        --bs-btn-active-border-color: var(--ams-sp-color-button-dark-gray);
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #fff;
        --bs-btn-disabled-bg: var(--ams-sp-color-button-dark-gray);
        --bs-btn-disabled-border-color: var(--ams-sp-color-button-dark-gray);
    }
    .btn-secondary {
        --bs-btn-color: #fff;
        --bs-btn-bg: var(--ams-sp-color-button-light-gray);
        --bs-btn-border-color: var(--ams-sp-color-button-light-gray);
        --bs-btn-hover-color: var(--ams-sp-color-button-light-gray);
        --bs-btn-hover-bg: #fff;
        --bs-btn-hover-border-color: var(--ams-sp-color-button-light-gray);
        --bs-btn-focus-shadow-rgb: 49,132,253;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: var(--ams-sp-color-button-light-gray);
        --bs-btn-active-border-color: var(--ams-sp-color-button-light-gray);
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #fff;
        --bs-btn-disabled-bg: var(--ams-sp-color-button-light-gray);
        --bs-btn-disabled-border-color: var(--ams-sp-color-button-light-gray);
    }
    .btn-info {
        --bs-btn-color: var(--ams-sp-color-button-dark-gray);
        --bs-btn-bg: transparent;
        /*--bs-btn-border-color: var(--ams-sp-color-button-light-gray);*/

        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: transparent;
        /*--bs-btn-hover-border-color: var(--ams-sp-color-button-dark-gray);*/

        --bs-btn-focus-shadow-rgb: 49,132,253;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: var(--ams-sp-color-button-dark-gray);
        --bs-btn-active-border-color: var(--ams-sp-color-button-dark-gray);
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #fff;
        --bs-btn-disabled-bg: var(--ams-sp-color-button-dark-gray);
        --bs-btn-disabled-border-color: var(--ams-sp-color-button-dark-gray);
    }
    .btn, .btn-primary, .btn-secondary, .btn-info {
        &.btn-sm {
            --bs-btn-font-size: 12px;
            --bs-btn-padding-x: 12px;
            --bs-btn-padding-y: 3px;
            --bs-btn-font-weight: 900;
        }
    }
}