#ams-stegplaner {
    .ams-stegplaner-current-menu-start {
        .ams-mbrb-body-container, .ams-menu-items {
            height: 100%;
            .ams-sp-menu-item-start {
                height: 100%;
                .btn {
                    text-transform: uppercase;
                    font-weight: 700;
                    padding: 6px 36px;
                }
                .ams-sp-menu-item-start-top {
                    height: 80%;
                    padding: 30% 20% 10% 20%;
                    background-image: var(--image-background);
                    background-repeat: no-repeat;
                    background-color: transparent;
                    background-size: 50% auto;
                    background-position-y: 0%;
                    background-position-x: 50%;
                    .ams-sp-menu-item-start-top-bg {
                        height: 100%;
                        background-image: var(--image-background);
                        background-repeat: no-repeat;
                        background-color: transparent;
                        background-size: contain;
                        background-position-y: 50%;
                        background-position-x: 50%;
                    }
                }
                .ams-sp-menu-item-start-bottom {
                    height: 20%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}