#ams-stegplaner {
    .ams-stegplaner-start {
        background-image: var(--image-background);
        background-repeat: no-repeat;
        background-color: var(--ams-sp-color-water-blue);
        background-size: cover;
        background-position-y: 50%;
        background-position-x: 0;
        background-blend-mode: luminosity;
        cursor: pointer;
    }
    .ams-stegplaner-app:not(.ams-stegplaner-app-small) {
        .ams-stegplaner-start {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
            overflow: hidden;
            video {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                min-height: 100%;
                min-width: 100%;
                width: auto !important;
                height: auto !important;
                background-color: var(--ams-sp-color-water-blue);
                mix-blend-mode: luminosity;
            }
        }
    }
    .ams-stegplaner-app.ams-stegplaner-app-small {
        .ams-stegplaner-start {
            min-height: 56.25vw;
        }
    }
}
